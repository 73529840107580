// library
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

// services
import api from "services/api";
import getAboutUsListing from "services/get-about-us-listing";

// hooks
import useTemplateContext from "hooks/use-template-context";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import PageConnectWithUsDto from "dto/pages/page-connect-with-us-dto";
import ErrorResponseDto from "dto/services/error-response-dto";
import { ImperativeHandleAppPopupCardDto } from "dto/components/app-popup-card-dto";

//components
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppFooter from "components/app-footer";
import AppNavbar from "components/app-navbar";
import AppTextarea from "components/app-textarea";
import AppCheckbox from "components/app-checkbox";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPopupCard from "components/app-popup-card";
import AppSelectInput from "components/app-select-input";
import AppOfficeCard from "components/pages/page-connect-with-us/app-office-card";

// assets
import emailReceivedIcon from "assets/images/email-received.png";

const PageConnectWithUs = () => {
	const modalRef = useRef<ImperativeHandleAppPopupCardDto>(null);
	const [officeBranches, setOfficeBranches] = useState<any[]>([]);
	const { getFilteredResources } = useTemplateContext();

	const title = "All Good! We will get in touch soon!";

	const validationSchema = yup.object({
		companyName: yup.string().required("This field is required"),
		email: yup.string().email("Please enter a valid email address").required("This field is required"),
		phone: yup
			.string()
			.matches(/^[0-9]+$/, "Please enter a valid phone number")
			.min(10, "Please enter a valid phone number")
			.required("This field is required"),
		referralSourceId: yup.string().required("This field is required"),
		message: yup.string().required("This field is required"),
		terms: yup
			.boolean()
			.oneOf([true], "You must accept the Terms of Use and Privacy Policy to proceed")
			.required("This field is required")
			.test("isTouched", "You must accept the Terms of Use and Privacy Policy to proceed", function (value) {
				return this.parent.terms !== undefined;
			}),
	});

	const connectPage = useMemo(() => getFilteredResources("page.connect"), [getFilteredResources]);
	const getPageData = useCallback((key: any) => connectPage?.find((value) => value.key === key)?.value?.toString() ?? "", [connectPage]);
	const connectCoverHeader = useMemo(() => getPageData("page.connect.cover.header"), [getPageData]);
	const connectCover = useMemo(() => getPageData("page.connect.cover"), [getPageData]);
	const connectHeader = useMemo(() => getPageData("page.connect.header"), [getPageData]);
	const connectDescription = useMemo(() => getPageData("page.connect.description"), [getPageData]);
	const officeHeader = useMemo(() => getPageData("page.connect.office.header"), [getPageData]);
	const officeDescription = useMemo(() => getPageData("page.connect.office.description"), [getPageData]);
	const formCompany = useMemo(() => getPageData("page.connect.form.companyName"), [getPageData]);
	const formEmail = useMemo(() => getPageData("page.connect.form.email"), [getPageData]);
	const formPhone = useMemo(() => getPageData("page.connect.form.phone"), [getPageData]);
	const formMessage = useMemo(() => getPageData("page.connect.form.message"), [getPageData]);
	const formHearAboutUs = useMemo(() => getPageData("page.connect.form.hearAboutUs"), [getPageData]);

	//prettier-ignore
	const formik = useFormik<PageConnectWithUsDto>({
		initialValues: { companyName: "", email: "", phone: "", referralSourceId: "", message: "", terms: false },
		validationSchema,
		onSubmit: async (values) => {
			try {
				await api.post.contactUs(values);

				modalRef.current?.onHandleShow();
			} catch (error) {
				const err = error as Error | ErrorResponseDto;

				serveRequestErrors(err);
			}
		},
	});

	const getOfficeBranchesListing = useCallback(async () => {
		try {
			const response = await api.get.branches("active", { page: 0, size: 99 });

			setOfficeBranches(response.data.data.list.content);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	//prettier-ignore
	const onHandleChangeTnc = useCallback((name: string, value: boolean) => {
		formik.setFieldValue(name, value);
	}, [formik]);

	useEffect(() => {
		getOfficeBranchesListing();
	}, [getOfficeBranchesListing]);

	return (
		<div className="page-connect-with-us">
			<div className="connect-with-us">
				<AppNavbar />

				<HeaderCard backgroundImage={connectCover} title={connectCoverHeader} />

				<div className="services-section">
					<p className="services-section__title">{connectHeader}</p>

					<div className="services-section__header">
						{/* prettier-ignore */}
						<p className="connect-with-us__description">{connectDescription}</p>
					</div>
				</div>

				<form className="connect-with-us__form" onSubmit={formik.handleSubmit}>
					<section className="connect-with-us__form-container">
						{/*prettier-ignore*/}
						<AppInput required type="text" name="companyName" label={formCompany} placeholder="Enter your company name" value={formik.values.companyName} touched={formik.touched.companyName} error={formik.errors.companyName} onChange={formik.handleChange}/>

						{/*prettier-ignore*/}
						<AppInput required name="email" placeholder="Enter your email" type="email" label={formEmail} value={formik.values.email} touched={formik.touched.email} error={formik.errors.email} onChange={formik.handleChange}/>

						{/*prettier-ignore*/}
						<AppInput required type="tel" name="phone" label={formPhone} placeholder="Enter your phone number" value={formik.values.phone} touched={formik.touched.phone} error={formik.errors.phone} onChange={formik.handleChange}/>

						{/*prettier-ignore*/}
						<AppSelectInput required name="referralSourceId" label={formHearAboutUs} placeholder="Select" value={formik.values.referralSourceId} touched={formik.touched.referralSourceId} error={formik.errors.referralSourceId} loadOptions={getAboutUsListing} onChange={formik.handleChange} />

						{/*prettier-ignore*/}
						<AppTextarea required id="message" name="message" label={formMessage} placeholder="Enter your Message here..." value={formik.values.message} touched={formik.touched.message} error={formik.errors.message} onChange={formik.handleChange}/>
					</section>

					<div className="submit-container">
						<AppCheckbox required name="terms" value={formik.values.terms} touched={formik.touched.terms} error={formik.errors.terms} onChange={onHandleChangeTnc}>
							<p className="submit-container__text" id="agreeTnc">
								I agree with <a href="/terms">Terms of Use</a> and <a href="/privacy">Privacy Policy</a> <span className="checkbox__required"> *</span>
							</p>
						</AppCheckbox>

						<AppButton type="submit" id="submit" label="Send Your Message" className="button-section button" disabled={formik.isSubmitting} />
					</div>
				</form>

				<div className="services-section">
					<p className="services-section__title">{officeHeader}</p>

					<div className="services-section__header">
						{/* prettier-ignore */}
						<p className="connect-with-us__description">{officeDescription}</p>
					</div>
				</div>

				<div className="services-section">
					<div className="services-section__wrapper">
						{officeBranches.map((branch) =>
							//prettier-ignore
							<AppOfficeCard key={branch.id} title={branch.headquarter ? "Main Headquarters" : "Regional Office"} location={branch.fullAddress.country} address1={branch.fullAddress.address1} address2={branch.fullAddress.address2} address3={branch.fullAddress.address3} city={branch.fullAddress.region} state={branch.fullAddress.state} postcode={branch.fullAddress.postcode} email={branch.contactEmail} googleMapLink={branch.location}/>
						)}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />

				<AppPopupCard ref={modalRef} image={emailReceivedIcon} title={title} />
			</div>
		</div>
	);
};

export default PageConnectWithUs;
