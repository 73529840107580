const pathnames = {
	PageHome: "/",
	PageLearning: "/learning",
	PageArticle: "/article",
	PageAllArticles: "/all-articles",
	PageAllProducts: "/all-products",
	PageProjectShowCase: "/project-show-case",
	PageCareer: "/career",
	PageConnectWithUs: "/connect-with-us",
};

export default pathnames;
