//library
import { useMemo } from "react";

//hooks
import useTemplateContext from "hooks/use-template-context";

//dto
import AppFooterDto from "dto/components/app-footer/app-footer-dto";
import { SocialLinksDto } from "dto/pages/page-career-dto";

//components
import FooterLink from "components/app-footer/footer-link";
import FooterAddress from "components/app-footer/footer-address";

//assets
import twitterIcon from "assets/images/components/app-footer/twitter-icon.svg";
import facebookIcon from "assets/images/components/app-footer/facebook-icon.svg";
import linkedinIcon from "assets/images/components/app-footer/linkedin-icon.svg";

const AppFooter = () => {
	const { getFilteredResources } = useTemplateContext();

	const footerPage = useMemo(() => getFilteredResources("page.footer"), [getFilteredResources]);
	const footerLogo = useMemo(() => (footerPage?.find((value) => value.key === "page.footer.logo")?.value ?? "").toString(), [footerPage]);
	const footerDisclaimer = useMemo(() => (footerPage?.find((value) => value.key === "page.footer.disclaimer")?.value ?? "").toString(), [footerPage]);
	const emailHeader = useMemo(() => (footerPage?.find((value) => value.key === "page.footer.email.header")?.value ?? "").toString(), [footerPage]);
	const emailDescription = useMemo(() => (footerPage?.find((value) => value.key === "page.footer.email.description")?.value ?? "").toString(), [footerPage]);
	const connectHeader = useMemo(() => (footerPage?.find((value) => value.key === "page.footer.connect.header")?.value ?? "").toString(), [footerPage]);
	const Branches = useMemo(() => footerPage?.find((value) => value.key === "page.footer.branches")?.value as AppFooterDto[], [footerPage]);
	const footerConnect = useMemo(() => footerPage?.find((value) => value.key === "page.footer.connect")?.value as SocialLinksDto, [footerPage]);
	const termsOfUse = useMemo(() => footerPage?.find((value) => value.key === "page.footer.termsOfUse")?.value?.toString(), [footerPage]);
	const privacyPolicy = useMemo(() => footerPage?.find((value) => value.key === "page.footer.privacyPolicy")?.value?.toString(), [footerPage]);
	const cookiePolicy = useMemo(() => footerPage?.find((value) => value.key === "page.footer.cookiePolicy")?.value?.toString(), [footerPage]);

	return (
		<div className="app-footer">
			<div className="footer">
				<div className="footer__logo">
					<img src={footerLogo} alt="" />
				</div>

				<div className="footer__wrapper">
					{Branches?.length > 0 &&
						Branches.map((o, i) => (
							<FooterAddress key={i} title={o.country}>
								{/*prettier-ignore*/}
								<div className="footer-address__description">
									<p className="footer-address__address">{o.address1}</p>

									{o.address2 && <p className="footer-address__address">{o.address2}</p>}

									{o.address3 && <p className="footer-address__address">{o.address3}</p>}

									<p className="footer-address__address">{o.region}</p>

									{o.state && <p className="footer-address__address">{o.state} {o.postcode}</p>}
								</div>
							</FooterAddress>
						))}

					<FooterAddress title={emailHeader} description={emailDescription} />

					<FooterAddress title={connectHeader}>
						<div className="footer__cta-wrapper">
							{footerConnect?.facebook && <FooterLink url={footerConnect?.facebook} iconUrl={facebookIcon} label="Facebook" />}

							{footerConnect?.twitter && <FooterLink url={footerConnect?.twitter} iconUrl={twitterIcon} label="Twitter" />}

							{footerConnect?.linkedin && <FooterLink url={footerConnect?.linkedin} iconUrl={linkedinIcon} label="LinkedIn" />}
						</div>
					</FooterAddress>
				</div>

				<div className="footer__divider" />

				<div className="footer__section">
					{/*prettier-ignore*/}
					<p className="footer__text">Copyright © {new Date().getFullYear()} {footerDisclaimer}</p>

					<div className="footer__navigation">
						<FooterLink url={termsOfUse} label="Terms of Use" isTnc />

						<FooterLink url={privacyPolicy} label="Privacy Policy" isTnc />

						<FooterLink url={cookiePolicy} label="Cookie Policy" isTnc />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppFooter;
