//library
import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// common
import classNames from "common/class-names";

// hooks
import useTemplateContext from "hooks/use-template-context";

// dto
import AppNavbarDto, { NavigationDto } from "dto/components/app-navbar-dto";
import { Backdrop } from "@mui/material";

const AppNavbar = ({ className }: AppNavbarDto) => {
	const [open, setOpen] = React.useState(false);
	const { getFilteredResources } = useTemplateContext();

	const navigationPage = useMemo(() => getFilteredResources("page.header"), [getFilteredResources]);

	const navbarLogo = useMemo(() => (navigationPage?.find((value) => value.key === "page.header.logo")?.value ?? "").toString(), [navigationPage]);

	const navigationList = useMemo(() => navigationPage?.find((value) => value.key === "page.header.navigation")?.value as NavigationDto[], [navigationPage]);

	//prettier-ignore
	const navbarClassName = useMemo(() => (classNames({
		"app-navbar": true,
        ...(className && { [className]: true })
	})), [className]);

	const toggleSidebar = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};

	return (
		<div className={navbarClassName}>
			<div className="navbar">
				<div className="navbar__wrapper">
					<div className="navbar__container">
						<Link to={pathnames.PageHome}>
							<img src={navbarLogo} alt="" />
						</Link>

						<div className="nav-menu">
							{navigationList?.map((o, i) => (
								<Link className="nav-menu__item" to={o.pathName} key={i}>
									<p className="nav-menu__label">{o.title}</p>
								</Link>
							))}
						</div>
						<button type="button" className="nav-btn" onClick={toggleSidebar(!open)}>
							<span className="sr-only">Open sidebar</span>
							<svg className="icon" aria-hidden="true" viewBox="0 0 20 20">
								<path d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
							</svg>
						</button>
						<aside className={`sidebar ${open ? "sidebar-open" : ""}`} id="sidebar" aria-label="Sidenav">
							<div className="sidebar-content">
								<div className="sidebar-logo">
									<Link to={pathnames.PageHome}>
										<img src={navbarLogo} alt="" />
									</Link>
								</div>
								<ul className="sidebar-nav">
									{navigationList?.map((o, i) => (
										<li className="nav-item" key={i}>
											<Link className="nav-link" to={o.pathName} key={i}>
												<p className="nav-menu__label">{o.title}</p>
											</Link>
										</li>
									))}
								</ul>
							</div>
						</aside>
					</div>
				</div>
			</div>
			<Backdrop open={open} onClick={toggleSidebar(false)}></Backdrop>
		</div>
	);
};

export default memo(AppNavbar);
